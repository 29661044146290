/*
 * Copyright (C) 2022 Adrien Fery. All Rights Reserved.
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React from 'react';
import { Trans } from 'react-i18next';
import { constants } from '../../constants';
import { DownloadIcon } from '@heroicons/react/solid';
import { StaticImage } from 'gatsby-plugin-image';
import CTA from './cta';

const BlogCTA = () => (



  <div className="w-full">
    <div className="relative bg-gradient-to-r from-slate-900 to-indigo-900 rounded-2xl">
      <div className="relative max-w-7xl mx-auto px-4 py-6 sm:px-6 lg:px-8 lg:py-8">
        <div className="md:ml-auto md:w-full md:pl-10">
          <p className="mt-2 mb-0 text-white text-3xl font-bold tracking-tight sm:text-4xl"><Trans>Ready to master ChatGPT?</Trans></p>
          <p className="text-lg mb-0 leading-6 text-indigo-200">
            Get access to 100+ GPT prompt inspirations to include in your daily work to boost your productivty.
          </p>
          <div className="mt-2">
            <div className="inline-flex rounded-md shadow">
              <a
              href={constants.links.checkout}
              target="_blank"
              className="mt-8 inline-flex no-underline items-center rounded-md border border-transparent rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 px-5 py-3 font-bold shadow"
            >
              <DownloadIcon className='mr-2 -ml-1 w-4 h-4'></DownloadIcon>
              <Trans>Get full access now</Trans>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default BlogCTA;
